<template>
  <div class="inner-layout">

    <div class="page-title">마이페이지</div>

    <mypage-tab></mypage-tab>

    <div class="member-layout">
      <div class="profile-modify">
        <div class="profile-img cursor-pointer" @click="$refs.imageInput.click()">
          <div class="">
            <image-alt :src="profileUrl" alt-type="profile"></image-alt>
          </div>
        </div>
        <button class="profile-modify-btn"></button>
        <input type="file" accept="image/gif, image/jpg, image/jpeg, image/png" ref="imageInput" @change="uploadImage($event)" style="display:none;">
      </div>
      <div v-if="imageMsg" class="error-message">{{ imageMsg }}</div>

      <div class="input-title between">
        <div>아이디(이메일)</div>
        <div v-if="getTarget !== 'email' && isSns" class="sns-login-text"><i class="kakao"><img src="@/assets/img/common/sns_kakao_symbol.svg"></i>카카오 연동 계정</div>
      </div>
      <input-field v-model="email" disabled readonly/>

      <div class="input-title">닉네임<span class="color-1">*</span></div>
      <input-field v-model="name" placeholder="닉네임(2자~15자)" @focusout="chkName" :errorMessage="nameMsg" maxLength="15"/>

      <phone-verify ref="phoneVerify" :propPhone="phone" :isPropModify="true" :isPropSns="getTarget !== 'email' && isSns"/>

      <div class="input-title">생년월일</div>
      <input-field type="number" v-model="birthday" @focusout="chkBirthday" :errorMessage="birthdayMsg" pattern="\d*" maxLength="8" numberOnly placeholder="숫자 8자리 입력(예: 20220101)" />
    
      <div class="input-title">성별</div>
      <div class="filter-list col2">
        <label v-for="item in sexList" :key="`sex-${item.code}`" @click="setCode('sex', item.code)">
          <input type="radio" name="sex" :checked="item.code === sex">
          <span>{{item.text}}</span>
        </label>
      </div>

      <div v-if="getTarget !== 'email' && isSns" class="page-guide">
        <h3><i class="icon-img icon-notice2"></i>안내사항</h3>
        <p>카카오 연동 회원의 경우 카카오 이메일, 연락처 정보 변경 시 꼬랩 카카오 로그인 시 자동 변경됩니다.</p>
      </div>

      <div class="ta-right fs-13">
        <router-link to="/mypage/withdrawal" class="color-7">회원탈퇴 &gt;</router-link>
      </div>

      <div v-if="!isOk" class="mt-20">
        <div class="btn btn-x-large btn-outline color-7 btn-block">변경</div>
      </div>
      <div v-else class="mt-20" @click="save">
        <div class="btn btn-x-large color-1 btn-block">변경</div>
      </div>
    </div>
  </div>
</template>

<script>
import Compressor from 'compressorjs'
import {mapActions, mapGetters} from "vuex"

export default {
  name: "mypageModify",
  data() {
    return {
      profileData: null,
      profileUrl: null,
      email:null,
      name: null,
      originName: null,
      phone: null,
      originPhone: null,
      birthday : '',
      originBirthday: '',
      sex :null,
      originSex: null,
      sexList :[
        {code:'M', text:'남자'},
        {code:'F', text:'여자'},
      ],
      isSns: true,
      imageMsg: '',
      emailMsg: '',
      nameMsg: '',
      birthdayMsg: '',
    }
  },
  watch: {
    name() {
      this.nameMsg = ''
    },
    birthday() {
      this.birthdayMsg = ''
    }
  },
  computed: {
    ...mapGetters({
      getName: 'session/getName',
      getUrl: 'session/getUrl',
      getPhone: 'session/getPhone',
      getTarget: 'session/getTarget',
    }),
    isOk() {
      if (this.profileData
        || ( this.name && (this.name != this.originName) )
        || ( this.phone && (this.phone != this.originPhone) )
        || ( this.birthday && (this.birthday != this.originBirthday) )
        || ( this.sex && (this.sex != this.originSex) )) {
        return true
      }
      return false
    },
  },
  mounted() {
    this.url = this.getUrl === 'null' ? null : this.getUrl
    this.name = this.getName
    this.getMyInfo()
  },
  methods:{
    ...mapActions({
      setSession: 'session/setSession',
      setMsgPopup: 'common/setMsgPopup',
    }),
    async getMyInfo() {
      const { result, data } = await this.$api.getMyInfo()
      if (result === 'success') {
        const phone = this.$refs.phoneVerify.phone ? this.$refs.phoneVerify.phone : data.phone
        this.profileUrl = data.src
        this.email = data.email
        this.name = data.nickName
        this.originName = data.nickName
        this.phone = phone
        this.originPhone = phone
        this.birthday = data.birthDate
        this.originBirthday = data.birthDate
        this.sex = data.sex
        this.originSex = data.sex
        this.isSns = data.sns
      }
    },
    setCode(type, code) {
      this[type] = code
    },
    async uploadImage(event) {
      if (!event) return false
      event.stopPropagation()
      event.preventDefault()

      let result = false
      this.msg = null
      let files = event.target.files || event.dataTransfer.files

      if (files.length > 0) {
        for (let file of files) {
          if (!/^image\//.test(file.type)) {
            this.imageMsg = this.$msg('image.ext')
            return false
          }

          let fileExt = this.$getExts(file.name, false)
          let isImg = this.$isImages(fileExt)
          if (isImg) {
            if (file.size < this.$MBtoB(10)) { // 10MB
              result = true;
            } else {
              this.imageMsg = this.$msg('image.limit')
            }
          }

          if (result) {
            let vm = this

            const img = new Image()
            img.src = URL.createObjectURL(file)

            img.onload = () => {
              const isCompressor = fileExt === 'gif' || !(img.width > vm.$imageMaxWidth || img.height > vm.imageMaxHeight) ? false : true

              if (isCompressor) {
                const options = {
                  maxWidth: vm.$imageMaxWidth,
                  maxHeight: vm.imageMaxHeight,
                  success: (_file) => {
                    if (_file.size > vm.$MBtoB(10)) { // 리사이징 했는데도 용량이 큰 경우
                      vm.alertPopup('image.limit')
                      return false
                    }
                    let reader = new FileReader()
                    reader.onload = () => {
                      vm.profileData = reader.result
                      vm.profileUrl = URL.createObjectURL(_file)
                      event.target.value = ''
                    }
                    reader.readAsDataURL(_file)
                  },
                  error: (err) => console.log(err)
                }
                new Compressor(file, options)
              }
              else {
                let reader = new FileReader()
                reader.onload = function () {
                  vm.profileData = reader.result
                  vm.profileUrl = URL.createObjectURL(file)
                  event.target.value = ''
                }
                reader.readAsDataURL(file)
              }
            }
          }

        }
      }
    },
    async chkName() {
      this.nameMsg = ''
      if (!this.name) {
        this.nameMsg = this.$msg('required.input')
        return false
      }
      if (!this.name.patternCheck('name')) {
        this.nameMsg = this.$msg('name.check')
        return false
      }
      if (this.name === this.originName) {
        return true
      }
      const { result } = await this.$api.chkName({name: this.name})
      if (result === 'success') {
        return true
      }
      else {
        this.nameMsg = this.$msg('name.exist')
        return false
      }
    },
    async chkPhone() {
      if (!this.phone) {
        return false
      }
      if (!this.phone.patternCheck('phone')) {
        return false
      }
      return true
    },
    async chkBirthday() {
      const checkBirthday = (v) => {
        const today = new Date()
        let y = today.getFullYear()
        let m = today.getMonth() + 1
        let d = today.getDate()
        if (m.toString().length == 1) m = "0" + m
        if (d.toString().length == 1) d = "0" + d
        return Number(v) > Number(`${y}${m}${d}`)
      }

      this.birthdayMsg = ''
      if (!this.birthday) {
        this.birthdayMsg = this.$msg('required.input')
        return false
      }
      if (!this.birthday.toString().patternCheck('yyyymmdd')) {
        this.birthdayMsg = this.$msg('yyyymmdd.check')
        return false
      }
      if (checkBirthday(this.birthday)) {
        this.birthdayMsg = this.$msg('untilToday.check')
        return false
      }
      return true
    },
    async save() {
      if (this.imageMsg) return false
      if (!(await this.chkName())) return false
      if (!(await this.chkPhone())) return false
      if (!(await this.chkBirthday())) return false
      const { result, data } = await this.$api.modify({
        profileData: this.profileData,
        nickName: this.name,
        phone: this.phone,
        birthDate: this.birthday,
        sex: this.sex,
      })
      if (result === 'success') {
        this.name = data.nickName
        this.profileUrl = data.src ? data.src : ''
        this.phone = data.phone
        localStorage.setItem('name', this.name)
        localStorage.setItem('url', this.profileUrl)
        localStorage.setItem('phone', this.phone)
        this.setSession({
          name: localStorage.getItem('name'),
          url: localStorage.getItem('url'),
          phone: localStorage.getItem('phone'),
        })
        this.alertPopup()
      }
    },
    alertPopup() {
      this.setMsgPopup({
        type: 'alert',
        message: this.$msg('ok.update'),
        okay: 'save',
        okayCallback: () => {
          this.setMsgPopup()
          location.reload()
        },
      })
    },
  }
}
</script>
